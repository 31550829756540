.services_container{
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    gap: 3rem;

}
.service{
    background: var(--color-bg-variant);
    
    border-radius:0 0 2rem 2rem;
    border: 1px solid var(--color-primary) transparent;
    height: fit-content;
    transition: var(--transition);
   
}
.service:hover{
    background: transparent;
    border: 1px solid var(--color-primary) ;
}
/* .service_list_icon{
    margin-top: 6px;
} */
.service_head{
    background: var(--color-primary);
    border-radius: 0 0 2rem 2rem;
    height:fit-content;
    padding: 1rem;
}
.service_head > h3{
    text-align: center;
    color: var(--color-bg);
    font-size: 1rem;
}
.service_list{
    padding: 1rem;

}
.service_list li{
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.8rem;
}
.service_list_icon{
    color: var(--color-primary);
    margin-top: 2px;
}
.service_list p{
    font-size: 0.8rem;
}
@media screen and (max-width:1024px) {
    .services_container{
        display: grid;
        grid-template-columns:1fr 1fr;
        gap: 2rem;
        width: 70%;
      
    
    }
    .service{
        height: auto;
    }
}  
    
/* media queries for smailldevices make it responsive */
@media screen and (max-width:600px) {
    .services_container{
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        width: 85%;
    
    }
}