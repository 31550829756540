.footer_container{
    background:var(--color-primary) ;
    text-align: center;
    font-size: 0.9rem;
    padding: 3rem 0 ;
    margin-bottom: 0;
    
   
}

footer a{
    color: var(--color-bg);
}
.footer_icon{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}
.permalink{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}
.footer_socials{
    display: flex;
    justify-content: center;
    gap :2rem;
    margin-bottom: 2rem;
}
.footer_socials a{
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.6rem;
    border-radius: 0.7rem;
    font-size: 2rem;
    border: 1px solid transparent;
    transition: var(transition);
}

.footer_socials a:hover{
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}
.copyright{
    margin-bottom: 2rem;
    color: var(--color-bg);
}
@media screen and (max-width:600px) {
    .permalink{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
       
    }
       
}