.container_portfolio{
    display: grid;  
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    /* display: flex;
    gap: 2rem; */
}

/* .portfolio_item{
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
   object-fit: contain;
   min-width: 200px;
} */
.portfolio_item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}
/* .portfolio_image{
    overflow: hidden;
    border-radius: 2rem;
} */
.portfolio_item h3{
    margin: 1rem 0  1rem 0;
}

.portfolio_item {
    
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    height: fit-content;
    /* padding: 10px; */
    background: var(--color-bg-variant);
        padding: 1.3rem;
        border-radius: 2rem;
        border: 1px solid transparent;
        transition: var(--transition);
}
/* .portfolio_item video:hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    cursor: pointer;
} */

/* .portfolio_item:hover video::-webkit-media-controls {
    display: none;
} */

.portfolio_image {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
}

/* .portfolio_image video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
} */
.portfolio_image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit:cover;
}


/* video::-webkit-media-controls-timeline-container {
    background-color: #ccc;
    height: 4px;
}

video::-webkit-media-controls-timeline {
    background-color: red;
    height: 100%;
} */

/* .portfolio_item:hover .portfolio_image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
} */

.portfolio_item:hover video {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 70vh; */
    /* set height to 70% of viewport height */
    /* width: auto;
    max-width: 70vw; */
    /* set max width to 70% of viewport width */
    /* max-height: 70vh; */
    /* set max height to 70% of viewport height */
    /* z-index: 2; */
}

@media screen and (max-width:1024px) {
    .container_portfolio{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
       
    }
}  
    
/* media queries for smailldevices make it responsive */
@media screen and (max-width:600px) {
    .container_portfolio{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
       
       
    }
}