

video {
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; */

    width: 100%; /* Make the video fill the container horizontally */
    height: 100%; /* Make the video fill the container vertically */
    object-fit: contain; /* Maintain aspect ratio and cover the container */
     /*position: absolute; /* Position the video absolutely within the container */
  /*top: 0; /* Align video to the top */
 /* left: 0; */
}
