@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}
/* :root can be useful for declaring global CSS variables.if you want to change you can change here and change the whole project */
:root {
    --color-bg:#1f1f38;
    --color-bg-variant:#2c2c6c;
    --color-primary:#4db5ff;
    --color-primary-variant:rgba(77, 181,255, 0.4);
    --color-white:#fff;
    --color-light:rgba(255, 255, 255, 0.6);
    /* --color-bg: #f0f0f0;
        --color-bg-variant: #e5e5e5;
        --color-primary: #00bfff;
        --color-primary-variant: rgba(0, 191, 255, 0.4);
        --color-white: #fff;
        --color-light: rgba(255, 255, 255, 0.6); */
    --container-width-1g:75%;
    --container-width-md:86%;
    --container-width-ms:90%;
    --transition: all 400ms ease;
}
html{
    scroll-behavior: smooth;
}
/* to remove scrollbar */
::-webkit-scrollbar{
    display: none;
}
body{
    font-family: 'Poppins', sans-serif;
    background-color: var(--color-bg);
    color:var(--color-white);
    line-height: 1.7;/*spacing etween lines*/
}
/* general styling */
.container{
    width: var(--container-width-1g);
    margin: 0 auto; /*place in the middle horizontally */
    
}
h1,h2,h2,h4,h5{
    font-weight: 500;
}
h1{
    font-size: 2.5rem;
}
section{
    margin-top: 8rem;
   
}
section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}
section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}
.text-light{
    color: var(--color-light);
}
a{
    color: var(--color-primary);
    transition: var(--transition);
}
a:hover{
    color: var(--color-white);
}
.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}
.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}
.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);
}
p{
    font-size: small;
}
img{
    display: block;
    width: 100%;
    object-fit: cover;
}
/* media queries for tabletto make it responsive */
@media screen and (max-width:1024px) {
    .container{
        width: var(--container-width-md);

    }
    section{
        margin-top: 6rem;
    }
}
/* media queries for smailldevices make it responsive */
@media screen and (max-width:600px) {
    .container{
        width: var(--container-width-ms);

    }
    section{
        margin-top: 2rem;
    }
}