.contact_container{
 display: grid;
 grid-template-columns: 1fr 1fr;
}
.contact_article{
    background: var(--color-bg-variant);
    padding: 1.5rem;
    margin: 2rem;
    border-radius: 1.5rem;
    width:50%;
    border: 1px solid transparent;
    transition: var(--transition);
    text-align: center;

}
.contact_article:hover{
    background: transparent;
    border: 1px solid var(--color-primary-variant);
}
.contact_icon{
    font-size: 1.5rem;
}

.contact_article a{
    display: inline-block;
    font-size: 0.8rem;
}
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
form >input{
    background: transparent;
    border: 1px solid var(--color-primary-variant);
    padding: 1rem 0 2rem 1rem;
    color: var(--color-white);
    width: 100%;
    border-radius: 1rem;
   
}
form > textarea{
    background: transparent;
    border: 1px solid var(--color-primary-variant);
    padding: 1.5rem;
    color: var(--color-white);
    width: 100%;
    resize: none;
    border-radius: 1rem;
}
@media screen and (max-width:1024px) {
    .contact_container{
        display: grid;
        grid-template-columns:  1fr;
       }
     
}  
    
/* media queries for smailldevices make it responsive */
@media screen and (max-width:600px) {
    .contact_container{
        display: grid;
        grid-template-columns:  1fr;
       }
       
}