header{
    height: 115vh;
    overflow: hidden;
    padding-top: 7rem;
}
.header_container{
    text-align: center;
    height:100%;
    position: relative;
}
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;

}
.header_socials{
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    left: 0;
    bottom: 3rem; 
    
  
}
/* content to add after the class */
.header_socials::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
.me{
 background: linear-gradient(var(--color-primary),transparent) ;
 
 width: 22rem;
 height: 30rem;
 position: absolute;
 left: calc(50% - 11rem);
 margin-top: 2rem;
 border-radius: 12rem 12rem 0 0;
 overflow: hidden;
}
.scroll_down{
    display: flex;
    position: absolute;
    right: 0;
    bottom: 3rem;
    transform: rotate(90deg);
    font-size: 0.9rem;
}
/* media queries for tabletto make it responsive */
@media screen and (max-width:1024px) {
    header{
        height:98vh;
    }
}  
    
/* media queries for smailldevices make it responsive */
@media screen and (max-width:600px) {
    header{
        height:100vh;
        margin-bottom: 0;
    }
   .header_socials,.scroll_down{
    display: none;
   }
}