.container.testimonial_container{
    width: 50%;
    padding-bottom: 3rem;
}
.clientimage1{
    width: 4rem;
    border-radius: 50%;
    overflow: hidden;
    aspect-ratio: 1/1;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}
.testimonial{
    background: var(--color-bg-variant);
    text-align: center;
    border-radius: 2rem;
    user-select: none;/*one cannte select text*/
    padding: 2rem;
}
.client_review{
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
} 
.swiper-pagination-clickable swiper-pagination-bullet{
    background: var(--color-primary);
}

@media screen and (max-width:1024px) {
    .container.testimonial_container{
        width: 60%;
    }
}  
    
/* media queries for smailldevices make it responsive */
@media screen and (max-width:600px) {
    .container.testimonial_container{
        width: 85%;
    }
}