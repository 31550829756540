nav{
    display: flex;
    position: fixed;
    gap: 2rem;
    width: max-content;
    background: rgba(0, 0, 0, 0.3);
    padding: 0.7rem 1.7rem;
    display: block;
    border-radius: 2rem;
    z-index: 2;
    bottom: 2rem;
    backdrop-filter: blur(15px);
    left: 50%;
    transform: translate(-50%);
}
nav a{
    padding: 0.9rem;
    border-radius: 50%;
     
}
nav a:hover{
    background:rgba(0, 0, 0, 0.5) ;
}
nav a.active{
    background: var(--colour-bg);
    color: var(--color-white);
}